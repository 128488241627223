:root {
  font-family: montserrat, sans-serif;
  color: white;
}
body {
  padding: 0;
  margin: 0;
  background-color: black;
}
.alerts-wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
}
.map-container {
  overflow: hidden !important;
  height: 90vh !important;
  width: 100%;
  flex: 0.7;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: montserrat, sans-serif;
  background-color: #f97316;
  font-weight: 600;
  font-size: 2.5em;
  color: white;
  padding: 0.25em 0.5em;
  max-width: 100%;
}
@media screen and (max-width: 600px) {
  header {
    font-size: 2em;
  }
}
.home-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.mapboxgl-marker {
  z-index: 0 !important;
}
.marker-icon {
  height: 5em;
  width: 5em;
  aspect-ratio: 1/1;
  background-size: 100%;
  background-image: url("../public/assets/train-icon.png") !important;
}
.stopPopup,
.trainPopup {
  background-color: #004d93;
  padding: 0em 1em;
  position: absolute;
  z-index: 1 !important;
  border-radius: 1em !important;
}
.stopPopup h1 {
  font-size: 1.75em !important;
  font-weight: 300;
  line-height: 1em;
  color: white;
  width: 10em;
}
.mapboxgl-popup-close-button {
  aspect-ratio: 1/1 !important;
  background-color: #f97316 !important;
  font-size: 20px !important;
  position: absolute;
  z-index: 999;
  width: 25px;
  height: 25px;
  padding: 0px !important;
  border-radius: 10000% !important;
  color: white !important;
  fill: #f97316 !important;
}
.timetable-wrapper {
  max-height: 90vh;
  font-family: montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  flex: 0.3;
  background-color: black;
  padding-bottom: 1em;
  overflow-y: scroll;
  grid-gap: 1em 1em;
}
.status-light {
  width: 1em;
  height: 1em;
  border-radius: 100%;
  background-color: red;
  aspect-ratio: 1/1;
}
.timetable-wrapper h1 {
  margin-bottom: 0;
}
.timetable-wrapper h2 {
  font-size: 2em;
  font-weight: 500;
}
@media screen and (max-width: 800px) {
  .home-wrapper {
    display: block;
  }
  .map-container {
    margin: 0;
    height: 80vh !important;
  }
  .timetable-wrapper {
    margin: 0;
    overflow-y: visible !important;
    height: max-content;
    max-height: 90000vh;
  }
}
.timetable-item {
  flex-wrap: wrap;
  font-family: monospace;
  align-items: center;
  justify-content: center;
  grid-gap: 1em 1em;
  letter-spacing: 0.1em;
  display: flex;
  flex-direction: row;
  padding: 1.5em 0.75em;
  border-radius: 1em;
  max-width: 90%;
  background-color: rgba(255, 255, 255, 0.1);
}
.timetable-item h1,
h2,
h5 {
  margin: 0;
}
.trainPopup,
.stopPopup {
  display: flex;
  flex-direction: column;
  width: 20em;
  padding: 1em 1em;
  gap: 0.5em;
}
.trainPopup h3,
.stopPopup h1 {
  font-weight: 800;
  font-size: 1.75em;
  color: white !important;
  margin: 0;
}
.trainPopup h4,
.stopPopup h2 {
  color: white !important;
  margin: 0;
  font-size: 1.5em;
  font-weight: 300 !important;
}
.trainPopup .last-updated {
  color: white !important;
  margin: 0;
  font-size: 1.1em;
  font-weight: 300 !important;
}

.timetable-item h5 {
  font-size: 1.25em;
  font-weight: 100;
}
.green {
  background-color: green;
}
.red {
  background-color: red;
}
.yellow {
  background-color: yellow;
}
.gray {
  background-color: gray;
}
.icon {
  color: #f97316;
  font-size: 3em;
  padding-left: 0.5em;
  padding-right: 0.25em;
}
.hide {
  display: none;
}
.detailedview-background {
  overflow: hidden !important;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.close-icon {
  font-size: 2.2em;
  position: absolute;
  left: 0.4em;
}
.close-icon:hover {
  color: red;
  stroke: white;
}
.detailedview-wrapper {
  padding: 1.25em 0em;
  position: absolute;
  right: 0;
  top: 0;
  width: 35em;
  background-color: black;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  padding-bottom: 2em !important;
  margin-right: -2px;
  overflow-y: scroll;
  min-width: 20%;
}
.detailedview-wrapper h1 {
  margin: 0;
}
.detailedview-wrapper h2 {
  margin: 0;
  font-size: 1.4em;
  font-weight: 300 !important;
}
.detailedview-wrapper ul {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  padding-bottom: 2em;
}
.detailedview-wrapper li {
  font-size: 1.1em;
  list-style: none;
  display: flex;
  gap: 1em;
  max-width: 90%;
  align-items: center;
}
.detailedview-wrapper .time {
  display: flex;
  justify-content: center;
}
.disabled {
  color: gray;
}
.noscroll {
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  .noscroll {
    overflow: visible;
  }
  .detailedview-wrapper {
    width: 100%;
  }
}
.stopPopup {
  width: 40em;
  max-width: 60vw;
}
header h1 {
  margin: 0;
  font-size: 1em;
}
header .icon {
  font-size: 1em;
  color: white;
  z-index: 999;
  cursor: pointer;
}
.popup {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.popup-background {
  width: 100%;
  position: absolute;
  z-index: 999;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.popup {
  width: 20em;
  height: fit-content;
  border-radius: 1.5em;
  background-color: #f1f5f9;
  display: flex;
  justify-content: flex-start;
  padding: 1.5em 1em;
  color: #0f172a;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  box-shadow: -0.5em 0.5em 20px 0.001em rgba(0, 0, 0, 0.5);
}
.popup h1 {
  font-size: 1.5em;
  margin: 0;
  margin-right: auto;
}
.popup p {
  margin: 0;
  font-size: 1em;
  line-height: 1.5em;
  margin-top: -0.25em;
}
.popup h2 {
  margin: 0;
  margin-top: 0.2em;
  font-size: 1.25em;
  margin-right: auto;
}
.popup .spacer {
  width: 100%;
  height: 0.1em;
}
.hstack {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center !important;
}
.popup .icon {
  margin-right: auto;
  padding: 0;
  margin: 0;
  margin-right: 1em;
  font-size: 1.75em;
  color: black;
}
.popup .icon:hover {
  color: red;
  cursor: pointer;
}
.noscroll-all {
  overflow: hidden !important;
}
a {
  text-decoration: inherit;
  color: inherit;
}
a:hover {
  text-decoration: underline;
}
